import * as React from "react"
import { SummarySection, EducationSection, CourseSection, ExperienceSection, LogoSection, ToolsSection} from "../../components/resume"

const FirstPage = () => {
  return (
    <>
      <div className="flex flex-row z-10 page">
        <title>Imprimir Currículo | Beatriz Arantes</title>
        <div className="w-2/5 flex flex-col bg-white pr-12 space-y-12 pt-32 pb-24 pl-16">
          <SummarySection />
          <EducationSection />
          <CourseSection />
        </div>
        <div className="w-3/5 flex flex-col bg-gray-100 pl-12 space-y-12 py-24 pr-16">
          <LogoSection />
          <ExperienceSection from={0} to={2} />
        </div>
      </div>
    </>
  )
}

const SecondPage = () => {
  return (
    <>
      <div className="flex flex-row z-10 page">
        <div className="w-2/5 flex flex-col bg-white pr-12 space-y-12 pt-24 pb-24 pl-16">
          <ToolsSection />
        </div>
        <div className="w-3/5 flex flex-col bg-gray-100 pl-12 space-y-12 pt-24 pb-24 pr-16">
        <ExperienceSection from={2} to={8} />
        </div>
      </div>
    </>
  )
}

const ResumePrintPage = () => {
  return (
    <>
      <main className="mx-auto flex flex-col">
        <FirstPage />
        <SecondPage />
      </main>
    </>
  )
}

export default ResumePrintPage
